exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bookings-js": () => import("./../../../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../../../src/pages/cancellation-policy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fees-js": () => import("./../../../src/pages/fees.js" /* webpackChunkName: "component---src-pages-fees-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-online-physio-consults-js": () => import("./../../../src/pages/services/online-physio-consults.js" /* webpackChunkName: "component---src-pages-services-online-physio-consults-js" */),
  "component---src-pages-services-physiotherapy-services-js": () => import("./../../../src/pages/services/physiotherapy-services.js" /* webpackChunkName: "component---src-pages-services-physiotherapy-services-js" */),
  "component---src-pages-services-supervised-exercise-js": () => import("./../../../src/pages/services/supervised-exercise.js" /* webpackChunkName: "component---src-pages-services-supervised-exercise-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/servicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */)
}

