/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// party town: https://www.gatsbyjs.com/blog/how-to-add-google-analytics-gtag-to-gatsby-using-partytown/

// gatsby-browser.js

export const onRouteUpdate = ({ location }) => {
    if (process.env.NODE_ENV !== 'production') {
        return null;
    }

    // const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    // setTimeout(() => {
    //     if (typeof gtag === 'function') {
    //         gtag('event', 'page_view', { page_path: pagePath });
    //     }
    // }, 900);
};